<template>
    <div
        class="vld-parent"
        :class="{ 'with-border': !collectionChart }"
        :style="collectionChart ? 'margin: 5px' : ''"
    >
        <pin-dialog v-model="pinDialog.show" :pinParams="pinParams" :title="pinDialog.title"/>

        <chart
            ref="bigFiveChart"
            :class="collectionChart ? 'mx-1' : ''"
            :config="updatedLocalConfig"
            :collectionChart="collectionChart"
            :pinTitle.sync="getTitle"
            stopClick
        >
            <template #chart-title>
                <div ref="title" v-html="getTitle"></div>
            </template>
            <template #before-menu v-if="showTools">
                <fe-icon-btn
                    v-if="!collectionChart"
                    useIcon="fas fa-thumbtack"
                    @click="pinDialog.show = true; pinDialog.title = 'Incidents ' + chartParams.title"
                />

                <fe-icon-btn useIcon="fas fa-expand-alt" @click="doExpand"/>
            </template>
            <template #after-chart v-if="collectionChart">
                <div v-if="subtitleFilters" class="d-flex justify-center chart-with-filters">
                    <template v-for="filter in subtitleFilters">
                        <fe-chip>{{ filter }}
                            <template #tooltip>{{ filter }}</template>
                        </fe-chip>
                    </template>
                    <span
                        v-if="moreFilters"
                        class="show-more-filters-btn"
                        @click="showMoreFilters = !showMoreFilters"
                    >
                            <u>{{ showMoreFilters ? 'Show More' : 'Show Less' }}</u>
                    </span>
                </div>
            </template>
        </chart>
        <div v-if="!collectionChart && subtitleFilters" class="d-flex justify-center chart-with-filters">
            <template v-for="filter in subtitleFilters">
                <fe-chip>{{ filter }}
                    <template #tooltip>{{ filter }}</template>
                </fe-chip>
            </template>

            <span
                v-if="moreFilters"
                class="show-more-filters-btn"
                @click="showMoreFilters = !showMoreFilters"
            >
                    <u>{{ showMoreFilters ? 'Show More' : 'Show Less' }}</u>
            </span>
        </div>

        <div
            :class="!collectionChart ? 'big5-date-picker' : 'big5-date-picker-collection'"
            :style="collectionChart ? {position: 'relative', bottom: getHeight, 'margin-right': getMargin} : {}"
        >
            <fe-title-select
                v-model="selectedRange"
                fontColor="#7e8494"
                fontSize="12px"
                itemText="name"
                itemValue="value"
                placeholder="Change date range"
                :items="rangeItems"
                @input="updateSingleChart"
                ref="incidents-chart-date-picker"
            />
        </div>

        <div class="loader" v-if="showLoader">
            <v-progress-circular
                class="loader-spinner"
                color="primary"
                indeterminate
            />
        </div>
    </div>
</template>

<script>
import PinDialog from '@/components/common/PinDialog'
import {mapState} from 'vuex'
import Chart from "./Chart";

export default {
    name: 'BigFiveChart',

    components: {Chart, PinDialog},

    props: {
        config: {required: true},
        height: {
            type: Number,
            default: 350
        },
        showTools: {
            type: Boolean,
            default: true
        },
        collectionChart: {
            type: Boolean,
            default: false
        },
        collectionChartData: {
            type: Object
        },
        incidentChart: {
            type: Boolean,
            default: false
        },
        pinTitle: {
            type: String,
            default: null
        },
        appliedFilters: {
            type: Array,
            default() {
                return []
            }
        }
    },

    data() {
        let me = this
        return {
            tooltip: function () {
                return this.series.name + " " + this.x + " " + this.y
            },
            showLoader: false,
            pinDialog: {
                show: false,
                title: ''
            },
            rangeItems: [{
                name: 'Last 30 Days',
                value: 30
            }, {
                name: 'Last 60 Days',
                value: 60
            }, {
                name: 'Last 90 Days',
                value: 90
            }],
            seriesColors: ['#006699', '#F59B00', '#08916D', '#529EE0', '#52B7D8', '#D9A6C2', '#FFB03B', '#54A77B', '#4FD2D2', '#F0E442', '#E287B2'],
            selectedRange: null,
            stacked: false,
            categories: {},
            localCategories: [],
            localConfig: {},
            updatedLocalConfig: {},
            chartParams: {},
            pinName: null,
            showMoreFilters: true,
            moreFilters: false,
            extraConfigs: {
                chart: {
                    marginBottom: 90,
                    marginTop: 90,
                    marginLeft: 60,
                    marginRight: 50,
                    spacingBottom: 5,
                    zoomType: 'xy',
                    height: 350,
                    type: 'column'
                },
                title: {
                    useHTML: true,
                    align: 'left',
                    style: {
                        'color': '#7E8494',
                        'border-bottom': '1px solid #E0E1E6',
                        'display': 'block',
                        'width': '100%'
                    },
                    text: ''
                },
                plotOptions: {
                    series: {
                        point: {
                            events: {
                                click(e) {
                                    let cfg = me.localConfig.config
                                    me.$emit('chartClicked', cfg.x_field, cfg.data.find(r => r[cfg.x_field] === e.point.category && r.school_year_name === e.point.series.name), e, me.localConfig.baseParams)
                                }
                            }
                        },
                        stacking: false,
                        dataLabels: {
                            enabled: true,
                            style: {
                                textOutline: 0
                            }
                        },
                        size: '100%'
                    }
                },
                yAxis: {
                    minPadding: 0,
                    maxPadding: 0,
                    endOnTick: false,
                    minimum: undefined,
                    maximum: undefined,
                    labels: {
                        style: {
                            fontSize: '10px'
                        },
                    },
                    tickInterval: undefined,
                    title: {
                        text: ''
                    }
                },
                xAxis: {
                    categories: [],
                    minPadding: 0,
                    maxPadding: 0,
                    labels: {
                        formatter() {
                            return (this.value + '').substring(0, 10)
                        },
                        style: {
                            fontSize: '10px',
                            color: 'gray'
                        }
                    }
                },
                tooltip: {
                    positioner: function (labelWidth, labelHeight, point) {
                        let x = point.plotX
                        let y = point.plotY + labelHeight - 8

                        if (point.plotY < 90) {
                            y = 90
                        }

                        return {x, y}
                    },
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    useHTML: true,
                },
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                legend: {
                    align: 'left',
                    shadow: false,
                },
                series: [],
                dash_id: null,
                saved_search_id: null,
                index: null
            }
        }
    },

    computed: {
        ...mapState('global', ['currentYear']),

        pinParams() {
            let p = {...this.localConfig.baseParams}
            p.dashboard_saved_search_type_id = 3
            p.chart_type = this.chartParams.chartType
            return p
        },

        series() {
            let series = []
            if (this.localConfig?.config && this.localConfig.config.data && this.categories.length) {
                let config = this.localConfig.config
                let data = this.$_.cloneDeep(this.localConfig.config.data)

                if (this.chartParams.chartType.includes('pyramid')) {
                    console.warn('pyramid charts have been moved to their own charting. Use IncidentRange.vue')
                } else {
                    data.forEach((rec, i) => {
                        let exists = series.find(serie => {
                            return serie.name === rec.school_year_name
                        })
                        if (!exists) {
                            series.push({
                                name: rec.school_year_name,
                                schoolYearId: rec.school_year_id,
                                color: this.seriesColors[series.length],
                                data: []
                            })
                        }
                    })

                    series.forEach(serie => {
                        if (this.categories.length > 0) {
                            this.categories.forEach(cat => {
                                let rec = data.find(data => data[config.x_field] === cat && serie.name === data.school_year_name)
                                let count = parseFloat(rec ? rec.total_count : 0)
                                this.minMax(count)
                                serie.data.push(count)
                            })
                        } else {
                            data.forEach(data => {
                                let count = parseFloat(data ? data.total_count : 0)
                                this.minMax(count)
                                serie.data.push(count)
                            })
                        }
                    })
                    this.localCategories = this.categories
                    this.extraConfigs.plotOptions.series.dataLabels.rotation = -90
                }
            }

            this.extraConfigs.yAxis.tickInterval = 10
            this.extraConfigs.title.text = this.localConfig.config.title
            this.extraConfigs.chart.height = this.height
            this.extraConfigs.xAxis.categories = this.localCategories
            if (this.config && this.config.search_id) {
                this.extraConfigs.dash_id = this.config.search_id.dashboard_id
                this.extraConfigs.saved_search_id = this.config.search_id.saved_search_id
            }
            if (this.config && this.config.index) {
                this.extraConfigs.index = this.config.index
            }

            return series
        },

        getHeight() {
            let mod = this.appliedFilters.length || this.localConfig.appliedFilters ? 10 : 55
            return this.height ? (this.height - mod).toString() + 'px' : '340px'
        },

        getMargin() {
            return !this.showTools ? '50px' : '0px'
        },

        getTitle: {
            get() {
                this.pinName = this.pinName || this.pinTitle || this.config.title
                return this.pinName
            },
            set(v) {
                this.pinName = v
            }
        },

        subtitleFilters() {
            if (!this.collectionChart && this.appliedFilters.length) {
                let filterArr = this.appliedFilters
                this.moreFilters = filterArr.length > 3
                return this.showMoreFilters && this.moreFilters ? filterArr.slice(0, 3) : filterArr
            } else if (this.collectionChart && this.localConfig.appliedFilters) {
                let filters = this.localConfig.appliedFilters
                if (filters) {
                    let filterArr = filters.split('|').filter(f => f !== '')
                    this.moreFilters = filterArr.length > 3
                    return this.showMoreFilters && this.moreFilters ? filterArr.slice(0, 3) : filterArr
                }
            }
        },
    },

    watch: {
        localConfig() {
            this.mergeConfigs()
        },

        extraConfigs() {
            this.mergeConfigs()
        }
    },

    mounted() {
        this.rangeItems = this.rangeItems.concat({
            name: this.currentYear.name,
            value: null,
            schoolYearId: this.currentYear.id
        })

        let year = this.config.baseParams.school_year_id || []
        if (!Array.isArray(year)) year = [year]

        if (this.config.baseParams.start_date && this.config.baseParams.end_date) {
            let start = this.config.baseParams.start_date
            let end = this.config.baseParams.end_date
            this.rangeItems.unshift({
                name: start + ' to ' + end,
                start: start,
                end: end
            })
        } else if (year.length) {
            let yr = ''
            // set the years that the user selected
            year.forEach((y, i) => {
                if (y) {
                    let f = this.$store.state.global.shareableStores.school_years.find(r => r.id === parseInt(y))
                    if (i) yr += ', '
                    yr += f.name
                } else {
                    yr = 'Custom Search'
                }

            })
            this.rangeItems.unshift({
                name: yr,
                value: null,
                schoolYearId: year
            })
        }

        if (this.config.baseParams.range && this.config.baseParams.range.value) {
            this.selectedRange = this.rangeItems.find(x => x.value === this.config.baseParams.range.value)
        } else {
            this.selectedRange = this.rangeItems[0]
        }

        this.localConfig = {...this.config}
        if (this.config.chartType === 'student') { // students chart type does not include fields
            let studentNames = []
            this.localConfig.config.data.forEach(r => {
                if (studentNames.indexOf(r.student_full_name) < 0) studentNames.push(r.student_full_name)
            })
            this.localConfig.categories = studentNames
        }
        this.categories = [...this.localConfig.categories]

        this.chartParams = {...this.localConfig}

        this.extraConfigs.series = this.series

        this.$emit('all-charts', this.$refs.bigFiveChart)

        if (this.collectionChart) {
            this.updateSingleChart(this.selectedRange, false)
        }

        this.addDataTest()
    },

    methods: {
        addDataTest() {
            //date picker
            let datePickers = this.$refs['incidents-chart-date-picker']
            datePickers = datePickers.$el.getElementsByClassName('fe-title-select')
            datePickers.forEach(datePicker => {
                datePicker.setAttribute('data-test','incidents-chart-date-picker')
            })
        },

        doExpand() {
            let name = ''
            if (!this.collectionChart && !this.incidentChart) {
                name = 'Create Incident'
            } else {
                name = this.pinName || this.pinTitle
            }
            this.$store.commit('global/addDockableWindow', {
                name: name,
                component: 'big-five-chart',
                attrs: {
                    config: this.localConfig,
                    height: window.innerHeight - 250,
                    showTools: false,
                    class: 'ma-5',
                    appliedFilters: this.appliedFilters,
                    collectionChart: this.collectionChart
                }
            })
        },

        minMax(count) {
            if (count < this.extraConfigs.yAxis.minimum || !this.extraConfigs.yAxis.minimum) this.extraConfigs.yAxis.minimum = count
            if (count > this.extraConfigs.yAxis.maximum || !this.extraConfigs.yAxis.maximum) this.extraConfigs.yAxis.maximum = count
        },

        buildSeries(categories, cfg) {
            let series = []

            cfg.data.forEach((rec, i) => {
                let exists = series.find(serie => {
                    return serie.name === rec.school_year_name
                })

                if (!exists) {
                    series.push({
                        name: rec.school_year_name,
                        schoolYearId: rec.school_year_id,
                        data: []
                    })
                }
            })

            series.forEach(serie => {
                categories.forEach(cat => {
                    let rec = cfg.data.find(data => data[cfg.x_field] === cat)
                    serie.data.push(rec ? rec.total_count : 0)
                })
            })

            return series
        },

        updateSingleChart(range, showLoader = true) {
            let p = {...this.config.baseParams}

            // When user changes date period (90 days, current year, etc.)
            // begin excluding any selected cohort school year so that backend
            // will not use it to limit results - we don't care about the
            // originally selected cohort school year anymore
            delete p.cohort_school_year_id

            if (range.start) {
                p.start_date = range.start
                p.end_date = range.end
            } else {
                p.start_date = range.value ? this.$dayjs().subtract(range.value, 'd').format('YYYY-MM-DD') : null
            }

            if (p.start_date) delete p.school_year_id
            else if (range.schoolYearId) p.school_year_id = range.schoolYearId

            let reloadParams = {...p}
            reloadParams.chart_types = [this.config.chartType]
            reloadParams.range = p.range
            delete reloadParams.saved_search_id
            this.showLoader = showLoader

            this.$axios.get(this.$models.getUrl('studentIncidentChart', 'read') + '&' + this.$objectToParams(reloadParams))
                .then(response => {
                    let data = this.$ecResponse(response, 'incidents')
                    let chart = data.charts[0]
                    let categories = chart.fields ? chart.fields : []

                    if (chart.chart_type === 'student') { // students chart type does not include fields
                        let studentNames = []
                        chart.data.forEach(r => {
                            if (studentNames.indexOf(r.student_full_name) < 0) studentNames.push(r.student_full_name)
                        })
                        this.categories = studentNames
                        categories = [...this.categories]
                    } else {
                        this.categories = categories
                    }

                    this.localConfig = {
                        title: chart.title,
                        chartType: chart.chart_type,
                        categories: categories,
                        config: chart,
                        baseParams: reloadParams,
                        appliedFilters: chart.filters_applied
                    }
                    this.showLoader = false
                })
        },

        mergeConfigs() {
            this.updatedLocalConfig = {}
            let o1 = this.localConfig.config
            let o2 = this.extraConfigs
            let objMerge = {}
            Object.keys(o2).forEach(key => {
                if (key === 'title') {
                    let text = o1.title
                    objMerge.title = Object.assign({}, {text}, o2.title)
                } else if (o2[key] instanceof Object) {
                    objMerge[key] = Object.assign({}, o1[key], o2[key]);
                } else {
                    objMerge[key] = o2[key];
                }
            })
            Object.assign(this.updatedLocalConfig, objMerge)
            this.updatedLocalConfig.xAxis.categories = this.localConfig.categories || []
            this.updatedLocalConfig.series = this.localConfig.series || this.series || []
        }
    }
}
</script>

<style lang="scss" scoped>
.tool-slot {
    position: absolute;
    top: 15px;
    right: 55px;
}

.fe-chart ::v-deep * {
    font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    color: #dddddd !important;
}

.big5-date-picker {
    position: absolute;
    top: 60px;
    right: 15px;

    &-collection {
        float: right;
        right: -140px;
    }
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #404040;
    opacity: .5;

    &-spinner {
        position: relative;
        left: 46%;
        top: 45%;
    }
}

.with-border {
    border: 1px solid #dddddd;
    border-radius: 4px;
    position: relative;
}

.show-more-filters-btn {
    align-items: center;
    display: flex;
    color: #006C90;
    font-size: 12px;
    cursor: pointer;
}

.chart-with-filters {
    position: relative;
    top: -45px;
    flex-wrap: wrap;
    width: 95%;
    margin-left: 12px;
    margin-bottom: -35px;

    ::v-deep .v-chip[hoverable] .v-chip__content {
        color: #050f2d;
        background-color: rgb(224, 225, 230);
    }
}
</style>
