<template>
    <div class="flex-column fill-height pa-5 landing-page">
        <div class="d-flex mb-4">
            <span style="font-size: 24px">Incidents</span>
            <v-spacer/>
            <fe-btn usage="secondary" @click="calcRiskRatio = true" data-test="calculate-risk-ratio-btn">
                Calculate Risk Ratio
            </fe-btn>
            <fe-btn usage="primary" @click="newIncident" data-test="new-incident-btn">
                New
            </fe-btn>
        </div>

        <v-flex row mb-3>
            <v-flex shrink class="text-center">
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                >
                    <template v-slot:activator="{ on }">
                        <fe-btn text v-on="on" usage="ghost" useIcon="arrow_drop_down" class="menu-btn"
                                useIconDirection="right" data-test="search-incidents-by-dropdown">
                            {{searchFilter.selected.name}}
                        </fe-btn>
                    </template>

                    <v-list class="fe-list">
                        <v-list-item
                            v-for="(opt, i) in searchFilter.options"
                            :key="'option-' + i"
                            style="cursor: pointer;"
                            @click="setFilter(opt)"
                        >
                            <v-list-item-content>{{opt.name}}</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-flex>

            <v-flex data-test="search-incidents-input">
                <v-autocomplete
                    :disabled="!searchFilter.selected.items.length"
                    :items="searchFilter.selected.items"
                    item-text="name"
                    style="width: 100%"
                    class="filter-field"
                    hide-details
                    return-object
                    flat
                    solo
                    dense
                    @change="search"
                />
            </v-flex>
        </v-flex>

        <div class="d-flex mb-3">
            <v-spacer/>
            <fe-card
                :headerText="'My Incidents for ' + myIncidents.year.my"
                class="alt-card incident-report upper-card"
                :menu="years.map(x => x.name)"
                @menu-item-clicked="updateIncidentYear"
                id="incident-my-incidents-card"
            >
                <template v-slot:body>
                    <v-flex row>
                        <v-flex @click="showDetail('entered')">
                            <div class="incident-report-count" data-test="my-incidents-entered-by-me-count">
                                {{myIncidents.entered.totalCount}}
                            </div>
                            <div class="incident-report-comparison">Entered By</div>
                        </v-flex>
                        <v-flex @click="showDetail('observed')">
                            <div class="incident-report-count" data-test="my-incidents-observed-by-me-count">
                                {{myIncidents.observed.totalCount}}
                            </div>
                            <div class="incident-report-comparison">Observed By</div>
                        </v-flex>
                    </v-flex>
                </template>
            </fe-card>
            <fe-card
                headerText="Open Incidents"
                class="alt-card incident-report upper-card"
                data-test="open-incidents-card"
            >
                <template v-slot:body>
                    <div class="incident-report-count-open" @click="showDetail('open')">
                        {{myIncidents.open.totalCount || 0}}
                    </div>
                </template>
            </fe-card>
            <v-spacer/>
        </div>

        <div class="d-flex mb-3">
            <div style="font-size: 18px; font-weight: 400;">What Incidents are happening?</div>
            <v-spacer/>
            <fe-title-select
                fontColor="black"
                fontSize="18px"
                itemText="name"
                itemValue="value"
                :items="rangeItems"
                v-model="startDate"
                @input="loadData"
            />
        </div>

        <fe-spinner v-if="loadingGroups"/>

        <div v-else-if="groups.length" class="d-flex row wrap">
            <div
                v-for="(group, i) in groups"
                class="incident-report lower-card"
                @click="groupDrilldown(group)"
            >
                <fe-card
                    :headerText="group.name"
                    :key="'incident-group-' + i"
                    class="alt-card"
                    :data-test="`incident-count-${group.name.toLowerCase()}-card`"
                >
                    <template v-slot:body>
                        <div class="incident-report-count">{{group.data.total_count}} Total</div>
                        <div class="incident-report-comparison">{{group.data.avg_per_day}} Average Per Day</div>
                    </template>
                </fe-card>
            </div>
        </div>

        <div v-else class="incident-report-count mt-4">No Incidents Reported</div>

        <risk-ratio-form
            v-if="calcRiskRatio"
            :currentYear="currentYear"
            @close="calcRiskRatio = false"
            @accept="displayRiskRatio"
        />

        <span class="incident-report-advanced-btn" @click="advancedSearch = true"><u>Advanced</u></span>
        <incident-advanced-search
            v-if="advancedSearch"
            @close="advancedSearch = false"
            @onSearchClick="searchAdvanced"
            data-test="search-incidents-advanced-btn"
        />

        <fe-crud ref="chartCrud" :config="chartCrudConfig" @read="parseChartData"/>
        <fe-crud ref="listCrud" :config="listCrudConfig"/>
        <fe-crud
            ref="typeOptsCrud"
            :config="typeOptionsCrudConfig"
            autoload
            @read="setSearchOptionList('incident_behavior_type_id', $event)"
        />
        <fe-crud
            ref="behaviorOptsCrud"
            :config="behaviorOptionsCrudConfig"
            autoload
            @read="setSearchOptionList('incident_behavior_id', $event)"
        />
        <fe-crud
            ref="locationOptsCrud"
            :config="locationOptionsCrudConfig"
            autoload
            @read="setSearchOptionList('incident_location_id', $event)"
        />
        <fe-crud
            ref="responseOptsCrud"
            :config="responseOptionsCrudConfig"
            autoload
            @read="setSearchOptionList('incident_response_id', $event)"
        />
        <fe-crud ref="yearsCrud" :config="yearsCrudConfig" autoload @read="years = $event.splice(0,5)"/>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import IncidentGrid from './IncidentGrid'
    import IFrameRenderer from '@/components/common/IFrameRenderer'
    import IncidentAdvancedSearch from "./IncidentAdvancedSearch"
    import RiskRatioForm from "./RiskRatioForm"

    export default {
        name: 'IncidentsLanding',

        components: {
            IncidentGrid,
            IFrameRenderer,
            IncidentAdvancedSearch,
            RiskRatioForm
        },

        data() {
            return {
                groups: [],
                years: [],
                loading: false,
                loadingGroups: true,
                showSummary: true,
                calcRiskRatio: false,
                advancedSearch: false,
                myIncidents: {
                    entered: {incidents: [], totalCount: 0},
                    observed: {incidents: [], totalCount: 0},
                    open: {incidents: [], totalCount: 0},
                    year: {my: [], open: []}
                },
                startDate: {
                    name: 'Last 30 Days',
                    value: 30
                },
                rangeItems: [{
                    name: 'Last 30 Days',
                    value: 30
                }, {
                    name: 'Last 60 Days',
                    value: 60
                }, {
                    name: 'Last 90 Days',
                    value: 90
                }, {
                    name: 'Year to Date',
                    value: null
                }],
                searchFilter: {
                    selected: {name: 'Search By...', items: []},
                    options: [{
                        name: 'Incident Type',
                        key: 'incident_behavior_type_id',
                        items: []
                    }, {
                        name: 'Incident Code',
                        key: 'incident_behavior_id',
                        items: []
                    }, {
                        name: 'Location',
                        key: 'incident_location_id',
                        items: []
                    }, {
                        name: 'Response',
                        key: 'incident_response_id',
                        items: []
                    }]
                }
            }
        },

        computed: {
            ...mapState('global', ['sessionUser', 'currentYear', 'defaultSchool']),

            chartCrudConfig() {
                let cfg = _.cloneDeep(this.$models.studentIncidentChart)
                cfg.read.params.chart_types = 'incident_behavior_type'

                if (this.startDate.value) {
                    cfg.read.params.start_date = this.$dayjs().subtract(this.startDate.value, 'd').format('YYYY-MM-DD')
                }

                return cfg
            },

            listCrudConfig() {
                return _.cloneDeep(this.$models.studentIncidentList)
            },

            typeOptionsCrudConfig() {
                return _.cloneDeep(this.$models.incidentBehaviorType)
            },

            behaviorOptionsCrudConfig() {
                return _.cloneDeep(this.$models.incidentBehavior)
            },

            locationOptionsCrudConfig() {
                return _.cloneDeep(this.$models.incidentLocation)
            },

            responseOptionsCrudConfig() {
                return _.cloneDeep(this.$models.incidentResponse)
            },

            yearsCrudConfig() {
                return _.cloneDeep(this.$models.schoolYear)
            },

            useNewIncidentCreation() { return this.$store.getters['flags/flags']['ec-incident-repo-move'] === true },
        },

        watch: {
            currentYear(v) {
                if (v) {
                    this.loadData()
                    this.loadMyIncidents(v)
                }
            }
        },

        mounted() {
            this.loadOpenIncidents()
            if (this.currentYear) {
                this.$nextTick(() => {
                    this.loadData()
                    this.loadMyIncidents(this.currentYear)
                })
            }
        },

        updated() {
            this.$nextTick(() => {
                this.addDataTests()
            })
        },

        methods: {
            addDataTests() {
                // three dot menu
                let threeDotMenu = document.getElementById('incident-my-incidents-card').getElementsByTagName('button')
                if (threeDotMenu.length > 0) threeDotMenu[0].setAttribute('data-test','my-incidents-threedot-menu')

                //date picker
                let datePicker = document.getElementsByClassName('fe-title-select')
                if (datePicker.length > 0) datePicker[0].setAttribute('data-test','incidents-date-picker')
            },

            loadMyIncidents(year) {
                let id = year.id
                let observedParams = {
                    observer_user_id: this.sessionUser.user.id,
                    school_year_id: id,
                    school_id: this.defaultSchool?.id,
                }
                this.$refs.listCrud.read(observedParams)
                    .then(r => {
                        this.myIncidents.observed = r.data
                    })

                let enteredParams = {
                    entry_user_id: this.sessionUser.user.id,
                    school_year_id: id,
                    school_id: this.defaultSchool?.id,
                }
                this.$refs.listCrud.read(enteredParams)
                    .then(r => {
                        this.myIncidents.entered = r.data
                    })

                this.myIncidents.year.my = [year.name]
            },

            loadOpenIncidents() {
                let openParams = {
                    open: 1,
                    school_id: this.defaultSchool?.id,
                }
                this.$refs.listCrud.read(openParams)
                    .then(r => {
                        let totalCount = r.data.totalCount
                        let incidents = r.data.incidents
                        this.myIncidents.open = {
                            totalCount: totalCount,
                            incidents: incidents
                        }
                    })
            },

            updateIncidentYear(year) {
                let newYear = this.years.find(x => x.name === year)
                this.loadMyIncidents(newYear)
            },

            loadData() {
                this.loadingGroups = true
                let id = this.currentYear.id
                let startDate = this.startDate.value ? this.$dayjs().subtract(this.startDate.value, 'd').format('YYYY-MM-DD') : null
                let chartParams = {
                    school_year_id: id,
                    start_date: startDate,
                    school_id: this.defaultSchool?.id,
                }

                this.$refs.chartCrud.read(chartParams).then(() => {
                    this.loadingGroups = false
                })
            },

            parseChartData({charts, params}) {
                let chartData = charts[0]
                let groups = chartData.data.map(rec => {
                    return {
                        name: rec[chartData.x_field],
                        data: rec,
                        params: {...params}
                    }
                })
                this.groups = groups
                this.groups.concat(groups)
            },

            showDetail(type) {
                if (type === 'open') {
                    this.$store.commit('global/addDockableWindow', {
                        name: 'Open Incidents',
                        component: 'open-incidents',
                        attrs: {
                            rowData: this.myIncidents.open.incidents
                        }
                    })
                } else {
                    this.$store.commit('global/addDockableWindow', {
                        name: 'My Incidents',
                        component: 'my-incidents',
                        attrs: {
                            observedRowData: this.myIncidents.observed.incidents,
                            enteredRowData: this.myIncidents.entered.incidents,
                            selected: type
                        }
                    })
                }
            },

            groupDrilldown(group) {
                let data = group.data
                let params = {
                    incident_behavior_type_id: [{
                        label: 'Incident Type',
                        name: data.incident_behavior_type_name,
                        key: 'incident_behavior_type_id',
                        id: data.incident_behavior_type_id
                    }],
                    school_year_id: [{
                        label: 'School Year',
                        name: data.school_year_name,
                        key: 'school_year_id',
                        id: data.school_year_id
                    }],
                }

                params.range = this.startDate
                if (group.params.start_date) {
                    params.start_date = group.params.start_date
                } else if (params.range.name === 'Year to Date') {
                    params.start_date = group.data.year_start
                }

                let filters = [{
                    label: 'Incident Type',
                    name: group.data.incident_behavior_type_name,
                    key: 'incident_behavior_type_id',
                    id: group.data.incident_behavior_type_id
                }]

                this.$emit('chart', params, filters)
            },

            newIncident() {
                if(this.useNewIncidentCreation) {
                    this.$store.commit('global/addDockableWindow', {
                        name: 'Create Incident',
                        component: 'incident-creation',
                        attrs: {}
                })
                } else {
                    this.$store.commit('global/addDockableWindow', {
                        name: 'Create Incident',
                        component: 'i-frame-renderer',
                        attrs: {
                            app: 'incident_creation'
                        }
                    })
                }
            },

            setFilter(opt) {
                this.searchFilter.selected = opt
            },

            setSearchOptionList(type, data) {
                this.searchFilter.options.forEach(x => {
                    if (x.key === type) {
                        x.items = data
                    }
                })
            },

            search(data) {
                let params = {school_year_id: [this.currentYear]}
                let selected = this.searchFilter.selected
                let filters = [{
                    label: selected.name,
                    name: data.name,
                    key: selected.key,
                    id: data.id
                }]
                params[selected.key] = [data]

                this.$emit('chart', params, filters)
            },

            searchAdvanced(params, filters) {
                this.advancedSearch = false
                this.$emit('chart', params, filters)
            },

            displayRiskRatio(params) {
                this.calcRiskRatio = false
                this.$store.commit('global/addDockableWindow', {
                    name: 'Risk Ratio Calculation',
                    component: 'risk-ratio',
                    attrs: {
                        params
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .landing-page {
        background: #F5F6F8;
        overflow: scroll;
    }

    .incident-report-advanced-btn {
        position: absolute;
        right: 55px;
        top: 93px;
        font-size: 12px;
        color: var(--v-primary-base);
        cursor: pointer;
    }

    .menu-btn {
        ::v-deep .v-btn {
            background: #fff !important;
            margin: 0 !important;
            padding: 0 !important;
            border: 1px solid #C0C3CF !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-right: none !important;
            min-width: 120px !important;
        }

        ::v-deep i {
            font-size: 20px !important;
        }

        ::v-deep .v-btn__content {
            padding-left: 5px;
        }
    }

    .filter-field {
        width: 230px;

        ::v-deep i {
            font-size: 16px !important;
        }

        ::v-deep .v-input__slot {
            border: 1px solid #C0C3CF !important;
        }

        ::v-deep .v-messages {
            display: none !important;
        }
    }

    .incident-report {
        height: 160px;
        margin: 16px;
        cursor: pointer;

        &.upper-card {
            width: 360px;
        }

        &.lower-card {
            width: 220px;

            ::v-deep .fe-card-title {
                .fe-card-title-text {
                    line-height: 2rem !important;
                    max-width: 95%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &:hover .fe-card-title-text {
                    white-space: pre-wrap;
                    word-break: break-word;

                    @at-root ::v-deep .fe-card-title:hover {
                        height: auto !important;
                    }
                }
            }
        }

        &-title {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 16px;
        }

        ::v-deep .fe-card-text-full {
            height: unset;
            padding: 33px 16px;
        }

        &-count {
            font-size: 20px;
            font-weight: 400;
            text-align: center;

            &-open {
                font-size: 26px;
                font-weight: 400;
                text-align: center;
                margin: 11px 0;
            }
        }

        &-comparison {
            color: #bbbbbb;
            text-align: center;
        }
    }

    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0;
    }

    .v-expansion-panel--active {
        .v-expansion-panel-header {
            min-height: unset;
        }
    }

    ::v-deep .fe-date-range-text-field {
        .v-input__control {
            .v-text-field__details {
                display: none;
            }
        }
    }

    ::v-deep .fe-title-select {
        font-weight: 400;
    }
</style>
