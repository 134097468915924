<template>
    <div class="flex-fill flex-column">
        <fe-crud-grid
            ref="grid"
            :columnDefs="columnDefs"
            :config="$models.studentAttachment"
            :flexColumns="true"
            :readParams="{ student_id: this.studentInfo.student_id }"
            :rowData="attachments"
            @beginCreate="showAddDialog = true"            
            @read="v => attachments = v"
            @destroyed="$emit('refreshData')"
            displayDensity="medium"
            disableInlineCreate
            refreshOnCreated
            refreshOnUpdated
            style="height: 400px"
            :showAddRowBtn="true"
            addRowBtnText="Upload Attachment"
        />

        <upload-attachment
            v-if="showAddDialog"
            @close="showAddDialog=false"
            :crud="$refs.grid.$refs.crud"
            @refreshDataEvt="$emit('refreshData')"
            :studentInfo="this.studentInfo"
        />

        <pdf-view
            v-if="showPdf"
            @close="showPdf=false"
            :crud="$refs.grid.$refs.crud"
            :pdfData="pdfData"
            :viewType="viewType"
        />
    </div>
</template>

<script>
    import UploadAttachment from './Upload'
    import PdfView from './PdfView'

    export default {
        name: 'StudentAttachment',

        props: {
            studentInfo: null
        },

        components: {
            UploadAttachment,
            PdfView
        },

        data() {
            return {
                showAddDialog: false,
                attachments: [],
                showPdf: false,
                imageSrc: null,
                pdfData: null,
                viewType: null,
            }
        },

        computed: {
            valuesLoaded() {
                return !!this.studentInfo
            },

            columnDefs() {
                let me = this

                return !this.valuesLoaded ? [] : [
                    {
                        headerName: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        width: 70,
                        minWidth: 70,
                        colId: 'checkbox_column'
                    }, {
                        headerName: "File",
                        field: "basename",
                        sortable: false,
                        editable: false
                    }, {
                        headerName: "Description",
                        field: "description",
                        sortable: false,
                        editable: false
                    }, {
                        headerName: "Uploaded By",
                        field: "user_full_name",
                        sortable: false,
                        editable: false
                    }, {
                        headerName: "Date",
                        field: "created",
                        sortable: false,
                        editable: false
                    }, {
                        headerName: "Download File",
                        field: "basename",
                        sortable: false,
                        editable: false,
                        cellRenderer(v) {
                            return `<div class="text-center"><i class="fas fa-cloud-download-alt" /i></div>`
                        },
                        cellStyle() {
                            return {
                                'cursor': 'pointer'
                            }
                        },
                        onCellClicked: (v) => this.doDownload(v)
                    }, {
                        headerName: "View File",
                        field: "path",
                        sortable: false,
                        editable: false,
                        cellRenderer(v) {
                            if (v.data.file_type.indexOf('application/pdf') > -1) {
                                return `<div class="text-center"><i class="fas fa-file-pdf" /></div>`
                            } else if (v.data.file_type.indexOf('image/') > -1) {
                                return `<div class="text-center"><i class="fas fa-image" /></div>`
                            }
                        },
                        cellStyle(v) {
                            if (v.data.file_type.indexOf('image/') > -1 || v.data.file_type.indexOf('application/pdf') > -1) {
                                return {
                                    'cursor': 'pointer'
                                }
                            }
                        },
                        onCellClicked: (v) => {
                            if (v.data.file_type.indexOf('image/') > -1 || v.data.file_type.indexOf('application/pdf') > -1) {
                                this.openPdf(v)
                            }
                        }
                    }
                ]
            }
        },

        mounted() {
            this.$refs.grid.$refs.crud.read()
        },

        methods: {
            doDownload(v) {
                let p = {
                    action: 'get',
                    property: 'file',
                    student_id: v.data.student_id,
                    id: v.data.id
                }

                let ext = v.data.basename.substr(v.data.basename.lastIndexOf('.') + 1)

                if (ext) {
                    this.$downloadFile('studentAttachment.php?' + this.$objectToParams(p), v.data.description, '.' + ext)
                } else {
                    this.$downloadFile('studentAttachment.php?' + this.$objectToParams(p), v.data.description)
                }
            },

            openPdf(v) {
                let p = {
                    property: 'file',
                    student_id: v.data.student_id,
                    id: v.data.id
                }
                let attachments = '&property=file&student_id=' + v.data.student_id + '&id=' + v.data.id

                if (v.data.file_type.indexOf('image/') > -1) {
                    this.$axios.get(
                        this.$models.getUrl('studentAttachment', 'read') + attachments, {
                            responseType: "blob",
                            headers: {
                                'Content-Type': 'image/*'
                            }
                        }
                    )
                    .then(response => {
                        let reader = new FileReader()
                        reader.readAsDataURL(response.data)
                        reader.onload = () => {
                            this.pdfData = reader.result
                            this.viewType = 'image'
                        }
                    })
                    .finally(() => {
                        this.showPdf = true
                    })
                } else if (v.data.file_type.indexOf('application/pdf') > -1) {
                    this.$axios.get(
                        this.$models.getUrl('studentAttachment', 'read') + attachments, {
                            responseType: "arraybuffer",
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    )
                        .then(response => {
                        const blob = new Blob([response.data])
                        const url = window.URL.createObjectURL(blob)
                        this.pdfData = url
                        this.viewType = 'pdf'
                    })
                    .finally(() => {
                        this.showPdf = true
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep div.ag-center-cols-container {
        width: 100% !important;
    }
</style>